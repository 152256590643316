import React from 'react';
import { Grid, Box } from '@mui/material';
import '../styles/services.css';
import Image from '../assets/Background3.png';
import PE from '../assets/background2.jpg';
import PR from '../assets/PR.jpg';
import JR from '../assets/JR.jpg';
import CP from '../assets/CP.jpg';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from '@mui/material/Button';
import Yard from '../assets/Yard.jpg';
import '../styles/home.css';

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#00000',
      darker: '#80808080',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  typography: {
    fontSize: 13, // Change this value to adjust the font size
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: '2px solid black', // Add border style here
          width: '80%',
        },
      },
    },
  },
});

function Services() {
  return (
  <>
  <h1 className='titleS'>Our Services</h1>
  <div className='imgBr'>
  <img src={Yard} alt="background" className="Obackground-image" />
  </div>
  <h1 className='MainWords2'>We specialize in supplying local businesses in the Greater San Francisco Bay Area with quality pallets. We also offer junk removal as an additional service. Contact us for a free quote today!</h1>
    <Grid container spacing={3} style={{marginBottom: '-15px'}}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <img className='sImg' src={PR}></img>
        <Box className='sBox'>
          <h2 className='sTitle'>Pallet Removal</h2>
          <p>If you have undesired pallets, give us a call & we'll come pick them up!</p>
          <ThemeProvider theme={theme}>
            <Button color='primary' href='./contact'>Contact Us</Button>
          </ThemeProvider>
          <div style={{paddingBottom: '10px'}}/>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
      <img className='sImg' src={PE}></img>
        <Box className='sBox'>
          <h2 className='sTitle'>Pallet Exchange</h2>
          <p>If you have used pallets & would like to exchange them, let us know how many!</p>
          <ThemeProvider theme={theme}>
            <Button color='primary' href='./contact'>Contact Us</Button>
          </ThemeProvider>
          <div style={{paddingBottom: '10px'}}/>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
      <img className='sImg' src={CP}></img>
        <Box className='sBox'>
          <h2 className='sTitle'>Custom Pallets</h2>
          <p>If you need custom built pallets in a specific size, we can build them!</p>
          <ThemeProvider theme={theme}>
            <Button color='primary' href='./contact'>Contact Us</Button>
          </ThemeProvider>
          <div style={{paddingBottom: '10px'}}/>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
      <img className='sImg' src={JR}></img>
        <Box className='sBox'>
          <h2 className='sTitle'>Junk Removal</h2>
          <p>If you have any junk you need removed, reach out to us for a free quote!</p>
          <ThemeProvider theme={theme}>
            <Button color='primary' href='./contact'>Contact Us</Button>
          </ThemeProvider>
          <div style={{paddingBottom: '10px'}}/>
        </Box>
      </Grid>
    </Grid>
  </>
  );
};


export default Services;
