import { Navbar, NavbarBrand } from 'react-bootstrap';
import Button from '@mui/material/Button';
import React from 'react';
import Logo from '../assets/Logo.png';
import '../styles/navbar.css';
import { ThemeProvider, createTheme } from "@mui/material/styles";


const theme3 = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#000000',
      darker: '#80808080',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  typography: {
    fontSize: 16, // Change this value to adjust the font size
  },
  
});


function NavbarComponent() {

  return (
    <>
      <Navbar expand="lg">
        <NavbarBrand href='/'>
          <img src={Logo} alt='logo' className='logo2'></img>
        </NavbarBrand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <ThemeProvider theme={theme3}>
            <Button color='primary' href='./services'>Services</Button>
          </ThemeProvider>          
          <ThemeProvider theme={theme3}>
            <Button color='primary' href='./contact'>Contact</Button>
          </ThemeProvider>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavbarComponent;
