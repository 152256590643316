import Stripe from 'stripe';

const stripe = new Stripe('sk_test_51N7TRVBjTdSE6ZhfITUeDt1iDBHqTYuOmHOOh3UjObTF9iHVKaQlFIKU6BwtbpoWwSCqCTYETZgfEjJgp3R8Hy54001DxTgEuz');

let productsArray = [];

let isPopulated = false;

async function getProductData(id) {
  const product = productsArray.find(product => product.id === id);

  if (!product) {
    console.log("Product data does not exist for ID: " + id);
    return undefined;
  }
  return product;
}

async function populateProductsArray() {
    if (isPopulated) {
      // If productsArray is already populated, return it
      return productsArray;
    }
  
    const productsSet = new Set(); // Use Set to avoid duplicates
  
    const products = await stripe.products.list({ active: true });
    for (let product of products.data) {
      const prices = await stripe.prices.list({ active: true, product: product.id });
      for (let price of prices.data) {
        const productData = {
          id: price.id,
          title: product.name,
          price: price.unit_amount_decimal / 100,
          imageUrl: product.images[0], 
          description: product.description,
          metadata: JSON.stringify(product.metadata),
        };
        productsSet.add(JSON.stringify(productData)); // Add as a string to the Set
      }
    }
  
    // Convert the Set back to an array
    productsArray.length = 0;
    for (let product of productsSet) {
      productsArray.push(JSON.parse(product));
    }
  
    isPopulated = true;
    return productsArray;
}

export { productsArray, getProductData, populateProductsArray };
