import { Row, Col } from 'react-bootstrap';
import Background from '../assets/Background3.png'
import '../styles/home.css';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  status: {
    danger: '#000000',
  },
  palette: {
    primary: {
      main: '#000',
      darker: '#000000',
    },
    neutral: {
      main: '#000',
      contrastText: '#000',
    },
    grey: {
      300: '#000',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {         
          '@media (max-width: 768px)': {
            color: 'black',   
            borderColor: 'black'
          },
        },
      },
    },
  },
});

function Home() {

  return (
    <>
      <div className="home">
          <div className='imgDiv'>
          <img src={Background} alt="background" className="background-image" />
          </div>
          <Row>
            <Col className='actionCol'>
              <p className='MainWords'>Welcome to The Pallet Yard!</p>
              <ThemeProvider theme={theme}>
                <Button sx={{
                  '&:hover': {
                    color: (theme) => theme.palette.grey[300],
                  },
                }} onClick={() => window.location.href = '/services'} variant='outlined' className='journeyButton'>Begin</Button>
              </ThemeProvider>
            </Col>
          </Row>

      </div>
    </>
  );
}

export default Home;
