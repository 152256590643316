import React from 'react';
import { Grid, Box } from '@mui/material';
import Background from '../assets/Background3.png';

function Contact() {
  const phoneNumber = '+19258954847';
  const emailAddress = 'LivermorePalletCompany@gmail.com';
  const address = 'Livermore, CA 94551';

  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  const handleAddressClick = () => {
    window.open('https://maps.google.com/?q=' + encodeURIComponent(address));
  };

  return (
    <Grid container justifyContent="center" style={{ marginBottom: '-15px' }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems="center"
          textAlign={{ xs: 'center', md: 'left' }}
          p={2}
          boxShadow={1}
          borderRadius="10px"
          bgcolor="#d2d2d2" // Set the background color to blue
        >
          <Box mb={{ xs: 2, md: 0 }} mr={{ xs: 0, md: 2 }}>
            <img
              src={Background}
              alt="Circle Image"
              style={{
                width: '100%',
                height: '150px',
                borderRadius: '8px',
                objectFit: 'cover',
              }}
            />
          </Box>
          <Box>
            <h2 style={{ color: 'black' }}>Livermore Pallet Company</h2>
            <p style={{ color: 'black' }}>
              Phone Number:{' '}
              <a href={`tel:${phoneNumber}`} onClick={handlePhoneClick} style={{ color: 'black' }}>
                {phoneNumber}
              </a>
            </p>
            <p style={{ color: 'black' }}>
              Email:{' '}
              <a href={`mailto:${emailAddress}`} onClick={handleEmailClick} style={{ color: 'black' }}>
                {emailAddress}
              </a>
            </p>
            <p style={{ color: 'black' }}>
              Address:{' '}
              <a onClick={handleAddressClick} style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }}>
                {address}
              </a>
            </p>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Contact;
