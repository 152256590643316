import '../styles/footer.css'

import React from 'react';


function Footer() {


  return (
    <>
      <div className='footDiv'>
        <br />
        <h1 className="copyright">
          © Livermore Pallet Company 2024
        </h1></div>
    </>
  );
}

export default Footer;
